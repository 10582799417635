input[type="text"],
input[type="email"],
input[type="number"],
select {
    background: #fdf7e6;
    border: 1px solid #d6cbaa;
    border-radius: 4px;
    padding: 2px 23px;
    color: #080808;
    font-weight: $font_500;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #bababa;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #bababa;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #bababa;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fdf7e6 url("#{$icon-down-arrow}") no-repeat;
    background-size: 14px;
    background-position: 85% 50%;
}

@include media-breakpoint-up(lg) {
    input[type="text"],
    input[type="email"],
    input[type="number"],
    select {
        height: 51px;
    }
}

@include media-breakpoint-down(lg) {
    input[type="text"],
    input[type="email"],
    input[type="number"],
    select {
        height: 35px;
        font-size: 10px;
        line-height: inherit;
    }

    select {
        background-size: 10px;
    }
}
