h1,
h2,
h3,
h4 {
    font-weight: $font_600;
}

// h5 {
//   font-weight: $font_500;
// }

// h6{
//   font-weight: $font_400;
// }

// p {
//   letter-spacing: 0.24px;
// }

@include media-breakpoint-down(sm) {
    h4 {
        font-size: 14px;
    }

    h5 {
        font-size: 13px;
    }

    p {
        font-size: 14px;
        line-height: 24px;
    }

    a {
        font-size: 10px;
    }
}
