// .yc-customer-review__carousel {
//     .slick-list {
//         margin: 0 -10px;
//     }
// }

@include media-breakpoint-up(lg) {
    .yc-customer-review__carousel {
        .slick-list {
            padding: 32px 0 40px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .yc-customer-review__carousel {
        .slick-list {
            padding: 20px 0 40px;
        }
    }
}
