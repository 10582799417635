.partner-slider {
    .slick-dots {
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 0;

        > li {
            margin: 0 5px;

            button:before {
                font-size: 12px;
            }
        }
    }
}
