.yc-search-panel {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 8px;
    margin-bottom: 26px;

    .yc-btn {
        width: 100%;
        padding: 3px 30px 2px 23px;
        // margin-left: 1rem;
        max-width: fit-content;
        height: 38px;
    }

    .code-select {
        background: #ffffff;
        height: 38px;
        margin-right: 6px;
    }

    .form-select {
        max-width: fit-content;
        height: 38px;
        font-size: small;
        font-weight: 600;

        &:focus {
            box-shadow: none;
            border-color: #ceccc7;
        }
    }

    .create-customer {
        font-size: small;
        font-weight: 600;
        cursor: pointer;
        color: #425563;

        &:hover {
            color: #edb111;
        }
    }

    .search-input {
        min-width: 100px !important;
        width: 100% !important;
    }

    .input-label {
        font-size: small;
    }

    .sub-title {
        font-size: small;
        font-weight: 600 !important;
        color: #302403;
    }
}

@include media-breakpoint-up(lg) {
    .yc-search-panel {
        padding: 40px 35px 33px;
        .yc-btn {
            height: 38px;
        }
    }
}

@include media-breakpoint-down(xl) {
    .yc-search-panel {
        .yc-btn {
            font-size: 12px;
            line-height: inherit;
            margin-top: 0;
        }
    }
}

@include media-breakpoint-down(lg) {
    .yc-search-panel {
        padding: 20px;
    }
}

@include media-breakpoint-down(md) {
    .yc-search-panel {
        padding: 0 10px;
        // box-shadow: none;
        margin-bottom: 25px;
        margin-top: 0;
        padding-bottom: 6px;
    }
}

@include media-breakpoint-down(sm) {
    .form-select {
        height: 35px !important;
    }

    .yc-btn {
        margin-top: 0.5rem !important;
        height: 35px !important;
    }
}
