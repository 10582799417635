.yc-common-page {
    padding: 0 0 40px;

    .nav {
        .nav-item {
            margin-bottom: 5px;
            padding-right: 20px;
        }

        .nav-link {
            position: relative;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            opacity: 0.6;
            font-weight: $font_500;
            text-decoration: none;
            padding: 15px 30px 15px 20px;
            transition: all 0.4s ease;
            cursor: pointer;

            &.active {
                font-weight: $font_700;
            }

            &.active,
            &:hover {
                opacity: 1;
                box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);

                &:after {
                    content: "";
                    position: absolute;
                    background: transparent url("#{$icon-dw-ar-lg-dark}") no-repeat;
                    background-size: contain;
                    height: 7px;
                    width: 12px;
                    top: 0;
                    bottom: 0;
                    right: 15px;
                    margin: auto;
                    transform: rotate(270deg);
                }
            }
        }
    }

    &__banner {
        position: relative;
        background-color: #f2f2f2;

        h4,
        p {
            color: var(--black);
        }

        p {
            font-weight: $font_300;
        }
    }
}

@include media-breakpoint-up(lg) {
    .yc-common-page {
        &__banner {
            padding: 40px;

            h4 {
                font-size: 20px;
                line-height: inherit;
                margin-bottom: 0;
            }

            p {
                font-size: 14px;
                line-height: inherit;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .yc-common-page {
        .nav {
            .nav-link {
                font-size: 12px;
                line-height: inherit;
            }
        }

        &__banner {
            padding: 20px;

            h4 {
                font-size: 18px;
                line-height: inherit;
                margin-bottom: 0;
            }

            p {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .yc-common-page {
        padding: 0 0 30px;

        .nav {
            display: none;
        }

        &__banner {
            h4 {
                font-size: 16px;
            }

            p {
                font-size: 10px;
                line-height: inherit;
            }
        }
    }
}
