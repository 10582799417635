* {
  outline: 0 !important;
}

html.menu-visible {
  max-height: 100vh;
  overflow: hidden;
}

body.modal-open {
  overflow: hidden;
}

button {
  outline: none;

  &:focus {
    outline: none;
  }
}

ul {
  list-style: none;
}

body {
  background-color: #fafafa;

  &.menu-active nav {
    left: 0 !important;
  }
}

main {
  position: relative;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.yc-panel__time-date>.slick-list {
  padding-bottom: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.pac-container {
  z-index: 1060;
}

.sticky-footer {
  position: sticky;
  bottom: 0px;
  background: white;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 0px 6px #00000029;
  padding: 1rem;

  button {
    width: 100%;
  }

  h6 {
    color: #302403;
  }

  a {
    text-decoration: none;
    color: #b59436 !important;
    cursor: pointer;

    &::after {
      content: "\203A";
      margin-left: 4px;
      font-size: 17px;
      position: absolute;
    }
  }

  .btn-arrow {
    color: #FFFFFF !important;
    font-size: 12px;

    &::after {
      font-size: 18px;
      line-height: 0.65;
    }
  }
}

@include media-breakpoint-up(xxl) {
  main {
    // min-height: calc(100vh - 135px);
    min-height: calc(100vh - 85px);
  }

  .yc-common-page {
    // min-height: calc(100vh - 193px);
    min-height: calc(100vh - 143px);
  }
}

@include media-breakpoint-down(xl) {
  main {
    min-height: calc(100vh - 64px);
  }

  .yc-common-page {
    // min-height: calc(100vh - 113px);
    min-height: calc(100vh - 122px);
  }
}

@include media-breakpoint-up(md) {
  .sticky-footer {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .sticky-footer {
    h6 {
      font-size: 20px;
      // line-height: inherit;
      padding-right: 10px;
      font-weight: 700;

      b {
        font-size: 14px;
        font-weight: 700;
      }
    }

    a {
      font-size: 10px;
      // line-height: inherit;
      font-weight: 600;
    }

    button {
      margin-top: 15px;
      line-height: normal;
      font-size: 12px;
      font-weight: 700;
    }

    .btn-partial {
      width: 150px;
      height: 46px !important;
      margin-top: 0 !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .sub-details {
    p {
      font-size: 11px;
      line-height: inherit;
    }
  }
}

@media only screen and (max-width: 370px) {
  .sticky-footer {
    .btn-partial {
      min-width: 120px;
      max-width: 90px;
      height: auto !important;
    }

    .left-offset {
      margin-left: -4px;
    }

    .right-offset {
      margin-right: -4px;
    }
  }
}

@media only screen and (max-width: 320px) {
  .sticky-footer {
    .btn-partial {
      min-width: 110px;
      max-width: 90px;
      height: auto !important;
    }
  }
}

@media only screen and (max-width: 310px) {
  .sticky-footer {
    .btn-partial {
      min-width: 100px;
      max-width: 90px;
      height: auto !important;
    }
  }
}