.timepicker__header {
    background-color: #edb111 !important;
}
.timepicker-button {
    color: #edb111 !important;
}
.clock-face__number > span.active {
    background-color: #edb111 !important;
}
.clock-face__clock-hand {
    background-color: #edb111 !important;
}
.clock-face__clock-hand_minute::before {
    border-color: #edb111 !important;
}
// .timepicker-class {
// }
