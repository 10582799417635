tabset.my-task.tab-container {
    .nav-tabs {
        border-color: rgba(48, 36, 3, 0.15);
        padding-bottom: 16px;

        .nav-item {
            margin-right: 16px;

            &:last-of-type {
                margin: 0;
            }
        }

        .nav-link {
            border: none;
            margin: 0;
            padding: 16px 0;
            border-radius: 8px;
            background: #e8e8e8;

            &.active {
                background: var(--white);
            }

            &:hover,
            &:focus {
                border: none;
            }

            > p {
                font-weight: $font_600;
                font-size: 13px;
                line-height: 16px;
                color: var(--black);
                text-align: center;
                text-transform: uppercase;
                margin: 0;

                > span {
                    display: inline-block;
                    font-weight: $font_600;
                    font-size: 13px;
                    line-height: 16px;
                    color: var(--white);
                    min-width: 16px;
                    min-height: 16px;
                    background: #edb111;
                    border-radius: 30px;
                    margin-left: 8px;
                    padding: 0 3px;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    tabset.my-task.tab-container {
        .nav-tabs {
            .nav-item {
                width: 220px;
                height: 48px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    tabset.my-task.tab-container {
        .nav-tabs {
            .nav-item {
                width: calc((100% - 16px) / 2);
            }
        }
    }
}
