ngx-otp-input {
    .ngx-otp-input-box {
        margin: 0 !important;

        .ngx-otp-input {
            width: 32px !important;
            height: 36px !important;
            margin: 0 8px;
            padding: 2px;
            font-size: inherit;
            border: none;
            border-bottom: 2px solid #7C7C7C;
            border-radius: 0;
            background: none;
        }
    }
}

ng-otp-input {
    .ng-otp-input-wrapper {
        display: flex;
    }
    
    .otp-input {
        width: 32px !important;
        height: 36px !important;
        margin: 0 8px !important;
        padding: 2px !important;
        font-size: inherit !important;
        border: none !important;
        border-bottom: 2px solid #7C7C7C !important;
        border-radius: 0 !important;
        background: none !important;
    }
}

@include media-breakpoint-down(lg) {
    ngx-otp-input {
        .ngx-otp-input-box {
            .ngx-otp-input {
                width: 32px !important;
                height: 32px !important;
                margin: 0 4px;
                font-size: 12px;
                line-height: inherit;
            }
        }
    }

    ng-otp-input {
        .otp-input {
            width: 32px !important;
            height: 32px !important;
            margin: 0 4px !important;
            font-size: 12px !important;
            line-height: inherit !important;
        }
    }
}