// Colors
$colors: (
  --black: #000000,
  --white: #ffffff,
  --yellow: #edb111,
  --red:#d32600,
  --maire: #302403,
  --whiteSmoke: #f0f0f0,
  --snow: #f9f9f9,
  --earlyDawn: #fcf3d9,
  --nightRider: #2b2b2b,
  --goldenBrown: #9e7402,
  --green: #008000,
  --grey: #7c7c7c,
  --gainsboro: #dddddd,
  --gainsboroTwo: #e3e3e3,
  --snowTwo: #fbfbfb,
  --whiteSmoke2: #f2f2f2,
);

// Font Family - Montserrat
// $font_family_montserrat: "Montserrat", sans-serif;

// Font Family - Metropolis
$font_family_metropolis: "Metropolis", "Metropolis-fall", "Metropolis-fallback", sans-serif;

// Font Family - Impact
$font_family_imapct: "Impact", Haettenschweiler, 'Arial Narrow Bold', sans-serif;

// Font Family - Outfit
$font_family_outfit: 'Outfit', sans-serif;

// Font Weights
$font_100: 100; // Thin
$font_200: 200; // Extra-light
$font_300: 300; // Light
$font_400: 400; // Regular
$font_500: 500; // Medium
$font_600: 600; // Semi-bold
$font_700: 700; // Bold
$font_800: 800; // Extra-bold
$font_900: 900; // Black

// Image Path
$image-path: "/assets/images/";
$icon-path: "/assets/images/icons/";
$social-path: "/assets/images/social/";

// Sprite image
$sprite-image: "#{$image-path}yc.svg";

// Icons
$icon-down-arrow: "#{$icon-path}down-arrow.png";
$icon-down-arrow-dark: "#{$icon-path}down-arrow-dark.png";
$icon-right-arrow-dark: "#{$icon-path}right-arrow-dark.png";
$icon-dw-ar-sm-dark: "#{$icon-path}dw-ar-sm-dark.png";
$icon-dw-ar-lg-dark: "#{$icon-path}dw-ar-lg-dark.png";
$icon-dw-ar-sm-light: "#{$icon-path}dw-ar-sm-light.png";
$icon-dw-ar-lg-light: "#{$icon-path}dw-ar-lg-light.png";
$icon-dw-ar-lg-yellow: "#{$icon-path}dw-ar-lg-yellow.png";
$icon-chat: "#{$icon-path}chat.png";
$icon-slick-arrow: "#{$icon-path}slick-arrow.png";
$icon-check-user: "#{$icon-path}check-user.png";
$icon-left-arrow-dark: "#{$icon-path}left-arrow-dark.png";
$icon-left-arrow-dark-sm: "#{$icon-path}left-arrow-dark-sm.png";
$icon-left-arrow-yellow: "#{$icon-path}left-arrow-yellow.png";
$icon-dw-ar-lg-blue: "#{$icon-path}dw-ar-lg-blue.png";

$icon-select-dwn: "#{$image-path}select-dwn.png";

// Social
$icon-facebook: "#{$social-path}facebook.png";
$icon-twitter: "#{$social-path}twitter.png";
$icon-insta: "#{$social-path}insta.png";
$icon-youtube: "#{$social-path}youtube.png";
$icon-lb: "#{$social-path}lb.png";
$icon-heart: "#{$social-path}heart.png";