.copy-link {
    position: relative;
    width: 400px;

    .copy-link-input {
        width: 100%;
        border: 0.5px solid #CECECE;
        border-radius: 20px;
        padding: 10px 70px 10px 10px;
        line-height: 1;
        box-sizing: border-box;
        outline: none;
        font-size: small;
        max-height: 40px;
    }
    .copy-link-botton {
        position: absolute;
        right: 16px;
        top: 3px;
        bottom: 3px;
        border: 0;
        background: #edb111;
        color: #fff;
        outline: none;
        margin: 0;
        padding: 0 10px;
        border-radius: 20px;
        z-index: 2;
        font-size: small;
        font-weight: 600;

        &:hover {
            opacity: 0.7;
        }
    }
}
