tabset.faq-tab.tab-container {
    display: flex;
    flex-direction: column;

    &.faq-tab--mobile > ul {
        padding: 0;
    }

    .nav-tabs {
        display: flex;
        align-items: center;
        border-color: rgba(48, 36, 3, 0.15);

        .nav-item {
            width: 50%;
        }

        .nav-link {
            border: none;
            border-bottom: 1px solid transparent;
            opacity: 0.8;

            > span {
                display: none;
            }

            &.active {
                border-bottom-color: var(--yellow);
                opacity: 1;
            }

            > p {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                flex-wrap: wrap;
                font-weight: $font_600;
                color: var(--maire);
                margin: 0;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    tabset.faq-tab.tab-container {
        margin-top: 64px;

        .nav-tabs {
            padding: 0 57px;

            .nav-link {
                padding: 20px;
                border-radius: 10px 10px 0px 0px;

                > p {
                    font-size: 20px;
                    line-height: 24px;

                    img {
                        height: 24px;
                        margin-left: 7px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    tabset.faq-tab.tab-container {
        margin-top: 44px;

        .nav-tabs {
            padding: 0 16px;

            .nav-link {
                padding: 19px 5px 21px;
                border-radius: 8px 8px 0px 0px;

                > p {
                    font-size: 12px;
                    line-height: 15px;

                    img {
                        height: 16px;
                        margin-left: 7px;
                    }
                }
            }
        }
    }
}
