.yc-promotions__carousel {
    .slick-list {
        margin: 0 -20px;
        padding: 36px 0 32px;
    }
}

@include media-breakpoint-down(xl) {
    .yc-promotions__carousel {
        .slick-list {
            margin: 0 -10px;
            padding: 20px 0 40px;
        }
    }
}
