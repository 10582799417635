.yc-details-panel {
    background: #f8f8f8;
    border-radius: 8px;

    > div {
        margin-bottom: 15px;

        > div {
            margin-right: 35px;

            &:last-of-type {
                margin: 0;
            }
        }
    }

    h6 {
        font-size: 12px;
        line-height: 15px;
        font-weight: $font_700;
        color: var(--black);
        margin-bottom: 21px;
    }

    p {
        font-size: 12px;
        line-height: 15px;
        font-weight: $font_300;
        margin: 0;
        color: #425563;
        word-break: break-word;

        strong {
            font-size: 14px;
            line-height: 18px;
            font-weight: $font_400;
            color: var(--black);
            display: block;
            margin-top: 3px;
        }
    }

    &--readmore {
        position: relative;
        font-size: 14px;
        line-height: 18px;
        font-weight: $font_700;
        color: var(--black);
        padding-right: 20px;
        text-decoration: none;
        cursor: pointer;
        margin-top: 5px;
        display: inline-block;

        &:hover {
            color: var(--black);
        }

        &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 6px;
            background: url("#{$icon-dw-ar-lg-dark}") no-repeat;
            background-size: contain;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(180deg);
        }

        &.limit-ex-txt:after {
            transform: rotate(0);
        }
    }
}

@include media-breakpoint-up(lg) {
    .yc-details-panel {
        padding: 31px 37px;
        min-height: 450px;
    }
}

@include media-breakpoint-down(lg) {
    .yc-details-panel {
        padding: 20px;
        margin-bottom: 16px;
    }
}

@include media-breakpoint-down(md) {
    .yc-details-panel {
        margin-bottom: 0;
    }
}

@include media-breakpoint-down(sm) {
    .yc-details-panel {
        > div > div {
            margin-right: 15px;
        }

        h6 {
            font-size: 14px;
            line-height: inherit;
        }

        p,
        p strong {
            font-size: 12px;
            line-height: inherit;
        }

        &--readmore {
            font-size: 10px;
            line-height: inherit;
        }
    }
}
