.yc-breadcrumb {
    padding: 20px 0;

    .breadcrumb {
        margin: 0;

        .breadcrumb-item {
            font-size: 12px;
            line-height: inherit;
            color: var(--black);
            font-weight: $font_300;
            position: relative;

            a {
                color: var(--black);
                font-weight: $font_600;
                text-decoration: none;
            }

            & + .breadcrumb-item {
                padding-left: 20px;

                &:before {
                    content: "";
                    position: absolute;
                    background: transparent url("#{$icon-dw-ar-lg-dark}") no-repeat;
                    background-size: contain;
                    height: 5px;
                    width: 10px;
                    left: 5px;
                    top: -1px;
                    bottom: 0;
                    margin: auto;
                    transform: rotate(270deg);
                    padding: 0;
                    float: none;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .yc-breadcrumb {
        padding: 20px 0;

        .breadcrumb {
            .breadcrumb-item {
                font-size: 10px;
            }
        }
    }
}
